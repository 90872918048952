<template>
    <div>
        <Menu></Menu>
        <div class="w1200 m-auto">
            <div class="boxs">
                <div class="ExamListText">
                    考试列表
                </div>
                <p class="title">考试时间：{{ $route.query.star }}至{{ $route.query.end }}</p>
                <div class="examinationList2">
                    <div v-for="(item, index) in list.examination_list" :key="index">
                        <div>
                            <p class="fs24">笔试部分{{ index + 1 }}</p>
                            <p class="fs20 mt20" v-if="item.is_distribution == 0">作答状态 ：未答完 </p>
                            <p class="fs20 mt20" v-else>作答状态 ： 已答完</p>
                        </div>
                        <div v-if="item.is_distribution == 0"
                            @click="$router.push(`/WrittenExamination?id=${item.objective_id}`)" class="getAnswer">
                            前往答题
                        </div>
                    </div>
                    <div>
                        <div>
                            <p class="fs24">专业考试</p>
                            <p class="fs20 mt20" v-if="list.answer_id">作答状态 已答完</p>
                            <p class="fs20 mt20" v-else>作答状态 ： ：未答完</p>
                        </div>
                        <!-- <div v-if="list.answer_id"
                            @click="$router.push(`/ProfessionalExamination?id=${$route.query.id}&student_id=${student_id}&content=${list.content}&answer_id=${list.answer_id}`)"
                            class="getAnswer">
                            前往修改
                        </div> -->
                        <div v-if="list.answer_id">
                        </div>
                        <div v-else
                            @click="$router.push(`/ProfessionalExamination?id=${$route.query.id}&student_id=${student_id}&content=${list.content}`)"
                            class="getAnswer">
                            前往上传
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import Menu from '@/components/menu.vue'
import { getExaminationList } from "@/api/examination"
export default {
    components: { Menu },
    data() {
        return {
            list: {},
            student_id: '',
            numTow: 1
        }
    },
    mounted() {
        if (localStorage.getItem('numTow')) {
            this.num = location.getItem('numTow')
        }
        this.getExaminationListFn()
    },
    methods: {
        GetWrittenExamination() {
            this.WrittenExamination = true
            this.is_show = false

        },
        GetProfessionalExamination() {
            this.ProfessionalExamination = true
            this.is_show = false
        },
        getExaminationListFn() {
            getExaminationList({ examination_id: this.$route.query.id }).then(res => {
                if (res.code == 200) {
                    this.list = res.data
                    this.student_id = this.list.examination_list[0].student_id
                } else if (res.code == 411) {
                    this.$message.success(res.msg);
                    localStorage.setItem('is_pay', true)
                    this.$router.go(-1)
                }
            })
        }
    },

}
</script>
  
<style  scoped>
.boxs {
    margin-top: 30px;
    width: 1200px;
    height: 740px;

    padding: 20px;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.ExamListText {
    justify-content: center;
    color: #CD0000;
    display: flex;
    align-items: center;
    font-size: 22px;
}

.ExamListText::before {
    content: '';
    width: 80px;
    display: inline-block;
    margin-right: 20px;
    height: 2px;
    background: #CD0000;
}

.ExamListText::after {
    content: '';
    width: 80px;
    display: inline-block;
    height: 2px;
    margin-left: 20px;
    background: #CD0000;
}

.examinationList2 {
    width: 90%;
    margin: 20px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.examinationList2>div {
    width: 500px;
    padding: 20px;
    margin-bottom: 20px;
    box-sizing: border-box;
    height: 121px;
    background: #FFFFFF;
    border: 1px solid #999999;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.getAnswer {
    width: 148px;
    height: 48px;
    background: #CC0000;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 48px;
}

.KeepAnswering {
    width: 148px;
    height: 48px;
    border: 1px solid #CC0000;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #CC0000;
    line-height: 48px;

}

.UnableQuestions {
    width: 148px;
    height: 48px;
    background: #999999;
    border-radius: 8px;
    text-align: center;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 48px;
    cursor: not-allowed;
}

.title {
    text-align: center;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #CC0000;
    line-height: 30px;
    margin: 20px 0;
}
</style>